import { send as httpSend } from "@/services/Api";
import store from "@/store";
import { UserManager } from "oidc-client-ts";

const authSettings = {
  authority: window.oidcAuthority || "https://secure.lumeris.com/as",
  client_id: window.oidcClientId || "1f0942cd-5fc7-4f30-8d89-3845bdc1153b",
  redirect_uri: `${window.location.origin}/login/callback`,
  response_type: "code",
  scope: "openid profile",
  loadUserInfo: false
};

const getLoginType = async username => {
  const request = {
    path: `${document.config.baseApiUrl}/api/v1/public/security/userNameCheck?userName=${username}`,
    method: "post"
  };

  const response = await httpSend(request);
  return response.data.credentialsType;
};

const signIn = async (username, password) => {
  return authenticate(username, password);
};

const getClient = username => {
  if (!username) {
    username = localStorage.getItem("oidc.username");
  } else {
    localStorage.setItem("oidc.username", username);
  }

  return new UserManager({
    ...authSettings,
    extraQueryParams: { login_hint: username }
  });
};

const executeSSO = username => {
  try {
    const client = getClient(username);
    client.signinRedirect();
  } catch (error) {
    const errorDetails = {
      header: "Single-Sign On Failed",
      message: error
    };
    store.commit("errorHandler/toastableError", errorDetails);
  }
};

const authenticate = async (username, password, idToken) => {
  const payload = new FormData();
  if (username) payload.append("username", username);
  if (password) payload.append("password", password);
  if (idToken) payload.append("idToken", idToken);
  const method = "post";
  const path = document.config.authApi;
  const headers = { "Content-Type": "multipart/form-data" };
  const response = await httpSend({
    path,
    method,
    payload,
    headers
  });
  return response.data;
};

const handleCallback = async () => {
  const client = getClient();
  const tokenResponse = await client.signinCallback();

  return tokenResponse ? authenticate(null, null, tokenResponse.id_token) : Promise.resolve(null);
};

export default {
  getLoginType,
  executeSSO,
  signIn,
  handleCallback
};
